import React from "react"

/* global _paq */

export default function Talk() {
    function trackClick ()
    {
        _paq.push(['trackEvent', 'Contact', 'Email Link Click', 'hello@jean-baptiste.dev']);
    }
    return (
        <div>
            <h2>Let's talk:</h2>
            <p>
                Do you have a question? Please feel free to write me an email at <a href="mailto:hello@jean-baptiste.dev"
                onClick={trackClick} target="_blank" rel="noreferrer">hello@jean-baptiste.dev</a>
            </p>
        </div>
    )
}