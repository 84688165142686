import React from "react"

export default function Technology() {
    return (
        <div>
            <h2>Technologies I use or have used:</h2>
            <h3>Languages</h3>
            <p>Ruby, Python, PHP, Javascript, NodeJS, Golang, Dart</p>
            <h3>Frameworks</h3>
            <p>Rails, Django, ExpressJS, Symfony, Laravel</p>
            <h3>Databases</h3>
            <p>MySQL, PostgreSQL, Firebase Firestore, MongoDB, Redis</p>
            <h3>Organisation tools</h3>
            <p>Github, Gitlab, Trello, Slack, Notion</p>
            <h3>System Administration</h3>
            <p>Linux Debian, Linux Ubuntu, DNS Bind9, Dnsmasq, ISC DHCP Server, Clonezilla, Proftp, Iptables, UFW, Docker, Docker Compose, MySQL, MariaDB, MongoDB, Redis, AWS EC2, AWS S3</p>
            <h3>Other</h3>
            <p>Stripe, Firebase Functions, AWS Lambda, Firebase Hosting, Firebase Dynamic Links, Google Data Studio, ChromeOS, ChromiumOS, Adobe Photoshop, Adobe InDesign, Adobe Premiere Pro, Adobe Illustrator, Adobe Encore, Adobe Flash</p>
        </div>
    )
}