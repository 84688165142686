import React from "react"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import Talk from "../components/talk";
import Books from "../components/books";
import Certifications from "../components/certifications";
import Technology from "../components/technology";

export default function Home() {
    return (
        <Layout>
            <div>
                <h1
                    css={css`
            display: inline-block;
            border-bottom: 1px solid;
          `}
                >
                    Jean-Baptiste MITTET
                </h1>
                <p>Hi, welcome to my website. I'm a software engineer and a Cloud enthusiast currently living in Dublin, Ireland.</p>
                <Certifications/>
                <Books/>
                <Technology/>
                <Talk />
            </div>
        </Layout>
    )
}