import React from "react"
import {CertificationDone, CertificationComing} from "./certification";

export default function Certifications() {
    return (
        <div>
            <h2>Certifications:</h2>
            <CertificationDone
                certificationName="Professional Scrum Master™ I (PSM I)"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/a2790314-008a-4c3d-9553-f5e84eb359ba/image.png"
                certificationProofUrl="https://www.credly.com/badges/36277489-d40c-4d7f-a254-eced4f4caace/public_url"
                certificationCurriculumUrl="https://www.scrum.org/assessments/professional-scrum-master-i-certification"
                certificationIssueDate="April 20, 2024"
            />
            <CertificationDone
                certificationName="Amazon Web Services: SysOps Administrator – Associate"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/f0d3fbb9-bfa7-4017-9989-7bde8eaf42b1/image.png"
                certificationProofUrl="https://www.credly.com/badges/147fda1a-54b0-4144-8b8b-50e1fc55124f/public_url"
                certificationCurriculumUrl="https://aws.amazon.com/certification/certified-sysops-admin-associate"
                certificationIssueDate="November 18, 2023"
            />
            <CertificationDone
                certificationName="Google Cloud: Associate Cloud Engineer"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/08096465-cbfc-4c3e-93e5-93c5aa61f23e/image.png"
                certificationProofUrl="https://www.credly.com/badges/f270dfd8-51cc-44fe-9768-bbeef954b41f/public_url"
                certificationCurriculumUrl="https://cloud.google.com/certification/cloud-engineer?hl=en"
                certificationIssueDate="July 24, 2021"
            />
            <CertificationDone
                certificationName="HashiCorp: Certified Terraform Associate"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/99289602-861e-4929-8277-773e63a2fa6f/image.png"
                certificationProofUrl="https://www.credly.com/badges/66dcd06b-c6eb-4c22-81b4-b8a77468e3ee/public_url"
                certificationCurriculumUrl="https://www.hashicorp.com/certification/terraform-associate"
                certificationIssueDate="November 15, 2021"
            />
            <CertificationDone
                certificationName="Amazon Web Services: Cloud Practitioner"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png"
                certificationProofUrl="https://www.credly.com/badges/24cd2d38-0698-4bbd-a3fe-70035510283e"
                certificationCurriculumUrl="https://aws.amazon.com/certification/certified-cloud-practitioner"
                certificationIssueDate="March 07, 2021"
            />
            <CertificationDone
                certificationName="Microsoft Certified: Azure Fundamentals (AZ-900)"
                certificationPictureUrl="https://images.credly.com/size/340x340/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png"
                certificationProofUrl="https://www.credly.com/badges/27c17433-5b8a-4bf9-9307-702e1a8ac4c1"
                certificationCurriculumUrl="https://docs.microsoft.com/en-us/learn/certifications/azure-fundamentals"
                certificationIssueDate="September 04, 2021"
            />
            <CertificationDone
                certificationName="LFCA: Linux Foundation Certified IT Associate"
                certificationPictureUrl="https://images.credly.com/size/680x680/images/a2c14e0a-56cb-4990-809a-23b0850c1f96/Training_Badges_LFCA_for_acclaim.png"
                certificationProofUrl="https://www.credly.com/badges/c4d08b6b-eb94-48c5-bfc0-282136adeefb/public_url"
                certificationCurriculumUrl="https://training.linuxfoundation.org/certification/certified-it-associate/"
                certificationIssueDate="May 21, 2022"
            />

            <h3>Coming up:</h3>
            <ul>
                <CertificationComing
                    certificationName="AWS Certified Developer - Associate"
                    certificationUrl="https://aws.amazon.com/certification/certified-developer-associate"
                />
                <CertificationComing
                    certificationName="Google Cloud - Professional Cloud Developer"
                    certificationUrl="https://cloud.google.com/certification/cloud-developer?hl=en"
                />
            </ul>
        </div>
    )
}