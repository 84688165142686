import React from "react"
import { cx, css } from '@emotion/css'

const size200 = css`
  width:200px;
  height:200px;
`

const paddingBottomCertification = css`
  padding-bottom:20px;
`

const blockCertification = css`
  display:inline-block;
  @media (min-width:320px)  { width:100%; }
  @media (min-width:481px)  { width:100%; }
  @media (min-width:641px)  { width:100%; }
  @media (min-width:961px)  { width:100%; }
  @media (min-width:1025px) { width:50%; }
  @media (min-width:1281px) { width:50%; }
`

const awidth100 = css`
  width: 100%;
  display: block;
`

const centerImages = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const centerText = css`
  text-align: center;
`

const marginBottomNull = css`
    margin-bottom:0;
`


export function CertificationComing({ certificationName, certificationUrl }) {
    return (
        <li>
            <a href={certificationUrl} target="_blank" rel="noopener noreferrer">{certificationName}</a>
        </li>
    )
}

export function CertificationDone({ certificationName, certificationPictureUrl, certificationProofUrl, certificationIssueDate, certificationCurriculumUrl }) {
    return (
        <div className={cx(paddingBottomCertification, blockCertification)}>
            <h4 className={centerText}>{certificationName}</h4>
            <div className={centerImages}>
                <a href={certificationProofUrl} target="_blank" rel="noopener noreferrer">
                    <img src={certificationPictureUrl} alt={certificationName} className={size200}/>
                </a>
            </div>
            <p className={centerText}>Issued on {certificationIssueDate}</p>
            <p className={cx(centerText, marginBottomNull)}>
                <a href={certificationProofUrl} target="_blank" rel="noopener noreferrer" className={awidth100}>View the certificate online</a>
            </p>
            <p className={centerText}>
                <a href={certificationCurriculumUrl} target="_blank" rel="noopener noreferrer">View the certificate curriculum</a>
            </p>

        </div>
    )
}