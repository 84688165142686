import React from "react"
import Book from "./book";

export default function Books() {
    return (
        <div>
            <h2>Reading</h2>
            <ul>
                <Book bookDescription="Designing Data-Intensive Applications: The Big Ideas Behind Reliable, Scalable, and Maintainable Systems - Martin Kleppmann"
                      readMoreUrl="https://www.oreilly.com/library/view/designing-data-intensive-applications/9781491903063/"
                      buyProductUrl="https://www.amazon.com/gp/product/B06XPJML5D"
                />
                <Book bookDescription="UNIX and Linux System Administration Handbook 5th Edition - Nemeth Evi, Snyder Garth, Hein Trent R., Whaley Ben, Mackin Dan"
                      readMoreUrl="https://www.admin.com/"
                      buyProductUrl="https://www.amazon.com/gp/product/B075MK6LZ7"
                />
                <Book bookDescription="Site Reliability Engineering: How Google Runs Production Systems - Niall Richard Murphy, Betsy Beyer, Chris Jones, Jennifer Petoff"
                      readMoreUrl="https://www.oreilly.com/library/view/site-reliability-engineering/9781491929117/"
                      buyProductUrl="https://www.amazon.com/gp/product/B01DCPXKZ6"
                />
            </ul>
            <h3>Recently read: </h3>
            <ul>
                <Book bookDescription="A Philosophy of Software Design - John K. Ousterhout"
                      readMoreUrl="https://web.stanford.edu/~ouster/cgi-bin/aposd.php"
                      buyProductUrl="https://www.amazon.com/gp/product/173210221X"
                />
                <Book bookDescription="Deep Work: Rules for Focused Success in a Distracted World - Cal Newport"
                      readMoreUrl="https://www.calnewport.com/books/deep-work/"
                      buyProductUrl="https://www.amazon.com/gp/product/B013UWFM52"
                />
                <Book bookDescription="The Phoenix Project - Gene Kim, Kevin Behr, and George Spafford"
                      readMoreUrl="https://itrevolution.com/the-phoenix-project/"
                      buyProductUrl="https://www.amazon.com/dp/1942788290"
                />
                <Book bookDescription="Quand la machine apprend: La révolution des neurones artificiels et de l'apprentissage profond - Yann Le Cun"
                      readMoreDescription="Read more here - French"
                      readMoreUrl="https://www.odilejacob.fr/catalogue/sciences/informatique/quand-la-machine-apprend_9782738149312.php"
                      buyProductUrl="https://www.amazon.com/gp/product/B07Z5N3LQM"
                />
                <Book bookDescription="Le Plan Copenhague : retour d’XP d’une migration vers Le Cloud - Pascal Martin"
                      readMoreDescription="Read more here - French"
                      readMoreUrl="https://blog.pascal-martin.fr/post/le-plan-copenhague-retour-xp-migration-cloud/"
                      buyProductUrl="https://leanpub.com/tci/"
                      buyProducDescription="Leanpub Product Page"
                />
            </ul>
            <p>Please note that none of the above book links are affiliated.</p>
        </div>
    )
}